import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Select } from './Select'; // Assuming you have a custom Select component
import placeholderImage from '../../asstes/dash-icons/placeholderImage.svg';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import { fetchHomeEssentials, getPurchaseList, getServiceList } from '../../../axiosCalls/userdashboard';


const ProjectSideNavEdit = ({ propsValue, updatedData }) => {
    // Manage form data in a single state object
    const [formData, setFormData] = useState({
        project_name: propsValue.title || '',
        start_date: propsValue.start_date || '',
        end_date: propsValue.end_date || '',
        files: propsValue.files || [],
        images: propsValue.images || [],
        linked_purchases: JSON.parse(propsValue.linked_purchases) || [],
        linked_services: JSON.parse(propsValue.linked_services) || [],
        linked_home_essentials: JSON.parse(propsValue.linked_home_essentials) || [],
        notes: propsValue.notes || ''
    });

    // States for options of linked purchases, services, and home essentials
    const [purchaseList, setPurchaseList] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [homeEssentials, setHomeEssentials] = useState([]);

    // Fetch data for purchases, services, and essentials on component mount
    useEffect(() => {
        const fetchEssentials = async () => {
            try {
                const response = await fetchHomeEssentials();
                const optionsData = response.data.items.map(item => ({
                    label: `${item.type} - ${item.model_number}`,
                    value: item.id,
                }));
                setHomeEssentials(optionsData);
            } catch (error) {
                console.error("Error fetching home essentials:", error);
            }
        };

        const fetchPurchases = async () => {
            try {
                const response = await getPurchaseList();
                const projectOptions = response.data.map(p => ({
                    value: p.id,
                    label: p.product_name,
                }));
                setPurchaseList(projectOptions);
            } catch (error) {
                console.error("Error fetching purchases:", error);
            }
        };

        const fetchServices = async () => {
            try {
                const response = await getServiceList();
                const projectOptions = response.data.map(p => ({
                    value: p.id,
                    label: p.service_name,
                }));
                setServicesList(projectOptions);
            } catch (error) {
                console.error("Error fetching services:", error);
            }
        };

        fetchServices();
        fetchPurchases();
        fetchEssentials();
    }, []); // The empty array ensures this effect runs only once after the component mounts

    // Handles input changes for text fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handles file upload changes
    const handleFileChange = (e, key) => {
        const newFiles = Array.from(e.target.files); // Handle multiple files
        setFormData((prevState) => ({
            ...prevState,
            [key]: [...prevState[key], ...newFiles]
        }));
    };

    // Removes file or image
    const removeFile = (index, key) => {
        setFormData((prevState) => ({
            ...prevState,
            [key]: prevState[key].filter((_, i) => i !== index)
        }));
    };

    // Handles changes for the Select component
    const handleSelectChange = (selected, key) => {
        setFormData((prevState) => ({
            ...prevState,
            [key]: selected
        }));
    };

    // Helper function to get file icon based on file type
    const getFileIcon = (file) => {
        if (file instanceof File) {
            if (file.type === 'application/pdf') {
                return pdfIcon;
            } else {
                return URL.createObjectURL(file); // Get object URL for image files
            }
        } else {
            const fileExtension = file.split('.').pop().toLowerCase();
            return fileExtension === 'pdf' ? pdfIcon : file;
        }
    };

    useEffect(() => {
        updatedData(formData)
    }, [formData])



    return (
        <div>
            <div className='side-nav'>
                <div className='side-header'>
                    <h3>Edit Project</h3>
                </div>
                <div className='side-body'>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Project Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="project_name"
                                    placeholder="Name"
                                    value={formData.project_name}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="start_date"
                                    value={formData.start_date}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="end_date"
                                    value={formData.end_date}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>

                            {/* Files Upload */}
                            <Form.Group md="12" className='mb-3'>
                                <div className='files-choosen'>
                                    <div className='title'>Files</div>
                                    <div className='button'>
                                        <div className="outline-yellow-button">
                                            Add file
                                            <input
                                                type='file'
                                                name='files'
                                                multiple
                                                accept='.png,.pdf,.jpg,.jpeg'
                                                className='chooeseFile'
                                                onChange={(e) => handleFileChange(e, 'files')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='listOfFiles'>
                                    {formData.files.map((file, index) => (
                                        <div className='image-choosed' key={index}>
                                            <a href={file instanceof File ? URL.createObjectURL(file) : file} target='_blank' rel='noopener noreferrer'>
                                                <img src={getFileIcon(file)} alt='File preview' />
                                            </a>
                                            <button
                                                type='button'
                                                onClick={() => removeFile(index, 'files')}
                                                className='image-choosed-crossed'
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>

                            {/* Images Upload */}
                            <Form.Group md="12" className='mb-3'>
                                <div className='files-choosen'>
                                    <div className='title'>Images</div>
                                    <div className='button'>
                                        <div className="outline-yellow-button">
                                            Add image
                                            <input
                                                type='file'
                                                name='images'
                                                multiple
                                                accept='.png,.jpg,.jpeg'
                                                className='chooeseFile'
                                                onChange={(e) => handleFileChange(e, 'images')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='listOfImages'>
                                    {formData.images.map((image, index) => (
                                        <div className='image-choosed' key={index}>
                                            <a href={image instanceof File ? URL.createObjectURL(image) : image} target='_blank' rel='noopener noreferrer'>
                                                <img src={image instanceof File ? URL.createObjectURL(image) : image} alt='Image preview' />
                                            </a>
                                            <button
                                                type='button'
                                                onClick={() => removeFile(index, 'images')}
                                                className='image-choosed-crossed'
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>

                            {/* Linked Purchases */}
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Linked Purchases</Form.Label>
                                <Select
                                    multiple
                                    options={purchaseList}
                                    value={formData.linked_purchases}
                                    onChange={(selected) => handleSelectChange(selected, 'linked_purchases')}
                                />
                            </Form.Group>

                            {/* Linked Services */}
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Linked Services</Form.Label>
                                <Select
                                    multiple
                                    options={servicesList}
                                    value={formData.linked_services}
                                    onChange={(selected) => handleSelectChange(selected, 'linked_services')}
                                />
                            </Form.Group>

                            {/* Linked Home Essentials */}
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Linked Home Essentials</Form.Label>
                                <Select
                                    multiple
                                    options={homeEssentials}
                                    value={formData.linked_home_essentials}
                                    onChange={(selected) => handleSelectChange(selected, 'linked_home_essentials')}
                                />
                            </Form.Group>

                            {/* Notes */}
                            <Form.Group as={Col} className="mb-3">
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="notes"
                                    rows={3}
                                    placeholder='Type here...'
                                    value={formData.notes}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ProjectSideNavEdit;
