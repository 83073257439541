import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import MagnifyingGlass from '../asstes/dash-icons/sidebar/MagnifyingGlass.svg'
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import pdfIcon from '../asstes/dash-icons/pdfIcon.svg'
import eyeIcon from '../asstes/dash-icons/eyeIcon.svg'
import downloadIcon from '../asstes/dash-icons/downloadIcon.svg'
import deleteIcon from '../asstes/dash-icons/deleteIcon.svg'
import previewImg from '../asstes/images/preview-img.jpg'
import previewPDF from '../asstes/images/preview-pdf.svg'
import FilesSideNavAdd from '../components/inc/filesSideNavAdd';
import FilesSideNavEdit from '../components/inc/filesSideNavEdit';
import ReminderSideNavCreate from '../components/inc/ReminderSideNavCreate';
import FilesSideNavView from '../components/inc/filesSideNavView';
import { addFileApis, getFilesList, updateFileApis } from '../../axiosCalls/userdashboard';
import { toast } from 'react-toastify';


const Files = () => {
    const [tabSelection, setTabSelection] = useState('table');
    const [continueChange, setContinueChange] = useState('createStep');
    const [showFileModal, setShowFileModal] = useState(false);
    const [fileToView, setFileToView] = useState(null);
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [checkCustomReminder, setCheckCustomReminder] = useState(false);
    const [NewFileData, setNewFileData] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [selectValue, setSelectValue] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortBy, setSortBy] = useState('upload_date');
    const [relatedTo, setRelatedTo] = useState('');
    useEffect(() => {

        const getListOfFile = async () => {
            try {
                const res = await getFilesList();
                setFileList(res.data)
            } catch (error) {

            }
        }
        getListOfFile();
    }, [])


    // Handling search query input
const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handling sorting by specific criteria
  const handleSortBy = (e) => {
    setSortBy(e.target.value);
  };

  // Handling filter by related to
  const handleRelatedTo = (e) => {
    setRelatedTo(e.target.value);
  };

  // Filtering the fileList based on search, sort, and relatedTo criteria
  const filteredData = () => {
    let filtered = fileList;

    // Filter based on search query
    if (searchQuery) {
      filtered = filtered.filter((item) => {
        // Check if any of the file URLs include the search query
        return item.files.some((fileUrl) =>
          fileUrl.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
    }

    // Sort based on the sortBy field (upload_date) and order (ASC/DESC)
    if (sortBy) {
      const [field, order] = sortBy.split('_');
      filtered = filtered.sort((a, b) => {
        if (order === 'ASC') {
          return new Date(a[field]) - new Date(b[field]);
        } else if (order === 'DESC') {
          return new Date(b[field]) - new Date(a[field]);
        }
      });
    }

    // Filter based on relatedTo value (file_name)
    if (relatedTo) {
      filtered = filtered.filter((item) => item.file_name === relatedTo);
    }

    return filtered;
  };


    const columnsFiles = [
        {
            name: "File name",
            selector: (row) => row.filename,
            sortable: false,
            reorder: true,
        },
        {
            name: "Related to",
            selector: (row) => row.Type,
            sortable: false,
            reorder: true,
        },
        {
            name: "Date",
            selector: (row) => row.date,
            sortable: false,
            reorder: true,
        },
        {
            name: "Actions",
            selector: (row) => row.action,
            sortable: false,
            reorder: true,
        },


    ];
    // Generate the dynamic dataFiles array

    // Helper function to get file icon based on file type
    const getFileIcon = (file) => {
        if (file instanceof File) {
            if (file.type === 'application/pdf') {
                return pdfIcon;
            } else {
                return URL.createObjectURL(file); // Get object URL for image files
            }
        } else {
            const fileExtension = file.split('.').pop().toLowerCase();
            return fileExtension === 'pdf' ? pdfIcon : file;
        }
    };
    // Function to handle file viewing
    const handleViewFile = (fileUrl) => {
        setFileToView(fileUrl);
        setShowFileModal(true);
    };
    // Function to handle file download
    const handleDownloadFile = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileUrl.split('/').pop()); // Set the download attribute with the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // Remove the link after download
    };

    const dataFiles = filteredData().flatMap((item, itemIndex) => {
        return item.files.map((fileUrl, fileIndex) => {
            const fileName = fileUrl.split('/').pop(); // Get the file name from the URL

            return {
                id: `${itemIndex + 1}-${fileIndex + 1}`, // Unique ID combining outer and inner loop indices
                filename: (
                    <div className='fileName'>
                        <img src={getFileIcon(fileUrl)} alt="file icon" className='fileItemImage' />
                        {`${fileName}`}  {/* Display file_name: actual filename */}
                    </div>
                ),
                action: (
                    <div className='actions'>
                        <span><img src={eyeIcon} alt="view icon" onClick={() => handleViewFile(fileUrl)} /></span>
                        <span><img src={downloadIcon} alt="download icon" onClick={() => handleDownloadFile(fileUrl)} /></span>
                        {/* <span><img src={deleteIcon} alt="delete icon" /></span> */}
                    </div>
                ),
                Type: <span className="services-badge type-green">{item.file_name}</span>,
                date: item.upload_date,  // Use the upload date from the current item
                data: item
            };
        });
    });
    // Function to determine the type of the file (PDF or image)
    const getFileType = (fileUrl) => {
        const fileExtension = fileUrl.split('.').pop().toLowerCase();
        if (fileExtension === 'pdf') {
            return 'pdf';
        } else if (['png', 'jpg', 'jpeg', 'gif'].includes(fileExtension)) {
            return 'image';
        }
        // Add more cases as needed
        return 'unknown'; // Default to unknown type
    };

    // Function to get preview source based on the file type
    const getPreviewSrc = (fileType, fileUrl) => {
        if (fileType === 'pdf') {
            return previewPDF;
        } else if (fileType === 'image') {
            return fileUrl;
        }
        return ''; // Default if no preview is available
    };
    // Dynamically create the previews array
    const previews = filteredData().flatMap((item) => {
        return item.files.map((fileUrl) => {
            const fileType = getFileType(fileUrl); // Get file type (pdf, image, etc.)
            const fileName = fileUrl.split('/').pop(); // Extract file name from URL

            return {
                type: fileType,
                src: getPreviewSrc(fileType, fileUrl),  // Preview based on file type
                name: `${fileName}`, // File name prefixed with the main file_name
                fileUrl: fileUrl,
                data: item
            };
        });
    });

    const handlerOpenViewFile = (e) => {
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        setContinueChange('ViewFileStep');
        setCheckCustomReminder(false);
        document.body.classList.add('no-scroll');
        setSelectValue(e.data)
    }
    const handlerNewSideNave = () => {
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        setContinueChange('createStep');
        setCheckCustomReminder(false);
        document.body.classList.add('no-scroll');
    }
    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        setCheckCustomReminder(false);
        document.body.classList.remove('no-scroll');
    }
    const upHandlerCustomReminder = (data) => {

        setNewFileData(data)
    }
    const handlerCustomReminder = (data) => {

        setNewFileData(data)
    }
    const handlerChangeForStep = async () => {



        if (continueChange === 'ViewFileStep') {
            setContinueChange('EditFileStep');
        }
        if (continueChange == 'createStep') {
            try {

                let data = new FormData();
                data.append('file_name', NewFileData.fileName);
                data.append('upload_date', NewFileData.uploadDate);

                //  data.append('related_to', '3');
                if (NewFileData.files) {
                    NewFileData.files.forEach(file => data.append('files[]', file));
                }
                if (NewFileData.reminderIds) {
                    NewFileData.reminderIds.forEach(remind => data.append('reminder_ids[]', remind));
                }


                const res = await addFileApis(data)
                if (res && res.status) {
                    toast.success(res.data.message)
                    handlerCancelSideNav()
                }
            } catch (error) {
                console.log("ERROR::", error)
            }
        }
        if (continueChange == 'EditFileStep') {
            try {

                let data = new FormData();
                data.append('file_name', NewFileData.fileName);
                data.append('upload_date', NewFileData.uploadDate);

                //  data.append('related_to', '3');
                if (NewFileData.files) {
                    NewFileData.files.forEach(file => data.append('files[]', file));
                }
                if (NewFileData.reminderIds) {
                    NewFileData.reminderIds.forEach(remind => data.append('reminder_ids[]', remind));
                }
                const res = await updateFileApis(data, selectValue.post_id)
                if (res && res.status) {
                    toast.success(res.data.message)
                    handlerCancelSideNav()
                }
            } catch (error) {
                console.log("ERROR::", error)
            }
        }
    }

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <h5 className="mb-2 text-titlecase mb-4">Files</h5>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext">
                            <div className="col-md-4 top-text-head">
                                <div className="reminder-top-button">


                                    <button className="filled-btn" onClick={handlerNewSideNave}>

                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00065 3.33594V12.6693M3.33398 8.0026H12.6673" stroke="white" strokeLinecap="round" />
                                        </svg>

                                        Add File</button>

                                </div>
                            </div>


                        </div>

                    </div>


                    <div className="row files_filters">
                        <div className="col-md-5 filesSearchArea">
                            <Form.Label></Form.Label>
                            <InputGroup className="search-field">
                                <InputGroup.Text id="basic-addon1">
                                    <img src={MagnifyingGlass} />
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                            </InputGroup>
                        </div>
                        <div className="col-md-5 d-flex File-sorting-table">
                            <div className="col-md-6">
                                <Form.Label>Sort By</Form.Label>
                                <Form.Select value={sortBy} onChange={handleSortBy}>
                                    <option value="upload_date">By Upload Date</option>
                                    <option value="ASC">By ASC</option>
                                    <option value="DESC">By DESC</option>
                                </Form.Select>
                            </div>
                            <div className="col-md-6">
                                <Form.Label>Related to</Form.Label>
                                <Form.Select value={relatedTo} onChange={handleRelatedTo}>
                                    <option value="">All</option>
                                    <option value="Home Essentials">Home Essentials</option>
                                </Form.Select>
                            </div>
                        </div>
                    </div>

                    {
                        tabSelection === 'table' ?



                            <div className='col-md-12 mb-3'>

                                <div className="recentFilesTable ">

                                    <DataTable
                                        columns={columnsFiles}
                                        data={dataFiles}
                                        defaultSortFieldId={2}
                                        selectableRows={false}
                                        selectableRowsNoSelectAll={false}
                                        selectableRowsVisibleOnly={false}
                                        selectableRowsHighlight={false}
                                        selectableRowsSingle={false}
                                        expandableRows={false}
                                        expandOnRowClicked={false}
                                        expandOnRowDoubleClicked={false}
                                        expandableRowsHideExpander={false}
                                        pagination={true}
                                        onRowClicked={handlerOpenViewFile}
                                        highlightOnHover={true}
                                        striped={false}
                                        pointerOnHover={false}
                                        dense={false}
                                        persistTableHead={true}
                                        noHeader={false}
                                        fixedHeader={false}
                                        fixedHeaderScrollHeight={"300px"}
                                        progressPending={false}
                                        noTableHead={false}
                                        noContextMenu={false}
                                        direction={Direction.AUTO}
                                        subHeader={false}
                                        subHeaderAlign={Alignment.RIGHT}
                                        subHeaderWrap={true}
                                        responsive={true}
                                        disabled={false}
                                        className='proteryReport'

                                    />

                                </div>
                            </div>
                            :

                            <div className='row mt-3'>
                                {previews.map((preview, index) => (
                                    <div className='previewParenet col-md-3 mb-3' key={index}>
                                        <div className='preview-item' >
                                            <div className='preview-item-image-section' onClick={() => handlerOpenViewFile(previews[0])}>
                                                {preview.type === 'image' ? (
                                                    <img src={preview.src} alt={preview.name} />
                                                ) : (
                                                    <img src={preview.src} alt={preview.name} type='application/pdf' />
                                                )}
                                            </div>
                                            <div className='preview-item-footer'>
                                                <div className='fileNametext'>{preview.name}</div>
                                                <div className='fileAction'>
                                                    <span><img src={eyeIcon} alt="view icon" onClick={() => handleViewFile(preview.fileUrl)} /></span>
                                                    <span><img src={downloadIcon} alt="download icon" onClick={() => handleDownloadFile(preview.fileUrl)} /></span>
                                                    {/* <span><img src={deleteIcon} alt="delete" /></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>


                    }


                </div>



            </div>

            <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

            {checkCustomReminder ?
                <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

                    <ReminderSideNavCreate />


                    <div className='side-footer'>

                        <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>

                        <button className='footer-button-primary ' >  {continueChange === 'createStep' ? "Save" : "Save"}</button>
                        {continueChange === 'createStep' ? '' : <button className='footer-button-delete footer-button-back' >Delete</button>}



                    </div>

                </div>

                :

                <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

                    {continueChange === 'createStep' ? <FilesSideNavAdd handlerCustomReminder={handlerCustomReminder} /> : continueChange === 'ViewFileStep' ? <FilesSideNavView selectValue={selectValue} /> : <FilesSideNavEdit selectValue={selectValue} upHandlerCustomReminder={upHandlerCustomReminder} />}


                    <div className='side-footer'>

                        <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>

                        <button className='footer-button-primary ' onClick={handlerChangeForStep}>  {continueChange === 'createStep' ? "Save" : continueChange === 'ViewFileStep' ? "Edit" : "Save"}</button>
                        {continueChange === 'createStep' || continueChange === 'ViewFileStep' ? '' : <button className='footer-button-delete footer-button-back' >Delete</button>}


                    </div>

                </div>
            }

            {/* Modal to View File */}
            <Modal show={showFileModal} onHide={() => setShowFileModal(false)} size="lg" centered>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    {fileToView && fileToView.endsWith('.pdf') ? (
                        <iframe
                            src={fileToView}
                            style={{ width: '100%', height: '500px' }}
                            frameBorder="0"
                            title="PDF Viewer"
                        />
                    ) : (
                        <img src={fileToView} alt="File" style={{ width: '100%' }} />
                    )}
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>




        </div>
    );
}


export default Files;
