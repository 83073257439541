import React, { useEffect, useState } from "react";
import RemindersCalender from "../components/inc/remindersCalender";
import RemindersTable from "../components/inc/remindersTable";
import ReminderSideNav from "../components/inc/reminderSideNav";
import { Form, InputGroup, Modal } from 'react-bootstrap';
import ReminderSideNavCreate from "../components/inc/ReminderSideNavCreate";
import ReminderSideNavEdit from "../components/inc/reminderSideNavEdit";
import MagnifyingGlass from '../asstes/dash-icons/sidebar/MagnifyingGlass.svg'
import google_calendar_icon from '../asstes/dash-icons/google_calendar_icon.svg'
import { addCustomReminder, addNotificationData, disconnect_google_account, get_user_google_token, refresh_token_if_needed } from "../../axiosCalls/userdashboard";
import { toast } from "react-toastify";
import apiCalendar from "../../google-calender/ApiCalendar";



const Reminders = () => {
    const [tabSelection, setTabSelection] = useState('calendar');
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [continueChange, setContinueChange] = useState('suggets');
    const [checkCustomReminder, setCheckCustomReminder] = useState(false);
    const [reminderData, setReminderData] = useState([]);
    const [backButton, setBackButton] = useState(false);
    const [isConnected, setIsConnected] = useState(false); // Track if the user is connected

    useEffect(() => {
        // Check if user is already connected (e.g., if access token exists in localStorage)
        const accessToken = localStorage.getItem('access_token');

        if (accessToken) {
            setIsConnected(true);
        } else {
            setIsConnected(false);
        }
    }, [isConnected]);


    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');

        const fetch_token_google_calendar = async () => {
            try {
                if (code) {
                    // If the `code` is present, fetch the token and store it
                    const tokenResponse = await get_user_google_token(code);
                    localStorage.setItem('access_token', tokenResponse.data.data.access_token);
                    window.location.href = '/reminders/';
                } else {
                    // If no `code` is present, check if a refresh token is stored and refresh if needed

                    const refreshResponse = await refresh_token_if_needed();
                    if (refreshResponse.data.status === 'success') {
                        // Update access token in localStorage
                        localStorage.setItem('access_token', refreshResponse.data.data.access_token);
                    } else {
                        console.error("Error refreshing token:", refreshResponse.data.message);
                    }

                }
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetch_token_google_calendar();
    }, []);

    const handleConnectGoogleCalendar = async (name) => {

        if (name === 'sign-in') {
            const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
            const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
            const scope = 'https://www.googleapis.com/auth/calendar';
            const responseType = 'code';
            const accessType = 'offline';
            const approvalPrompt = 'force';

            // Construct the Google OAuth2 URL
            const googleAuthUrl = `https://accounts.google.com/o/oauth2/auth?scope=${encodeURIComponent(scope)}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${responseType}&client_id=${clientId}&access_type=${accessType}&approval_prompt=${approvalPrompt}`;

            // Redirect the user to Google login
            window.location.href = googleAuthUrl;



        } else if (name === 'sign-out') {
            // Remove tokens from localStorage to sign out
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            setIsConnected(false); // Update state to show the "Connect" button again

            const response = await disconnect_google_account();


        }


    };

    const handlerReminderSideNave = () => {

        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        setCheckCustomReminder(false);
        document.body.classList.add('no-scroll');


    }
    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        setContinueChange('suggets');
        document.body.classList.remove('no-scroll');
        setCheckCustomReminder(false);
        setBackButton(true)



    }
    const handlerChangeStep = async () => {


        if (continueChange != 'suggets') {

            try {
                const responseCustomReminder = await addCustomReminder(reminderData);
                const response = await addNotificationData(reminderData, responseCustomReminder.data.post_id);
                if (response && response.data) {


                    if (response.data.status == 400) {
                        toast.error(response.message);

                    } else {
                        toast.success(response.data.message);
                        handlerCancelSideNav();
                    }
                }
            } catch (error) { }

        } else {

            try {
                const responseCustomReminder = await addCustomReminder(reminderData);
                const response = await addNotificationData(reminderData, responseCustomReminder.data.post_id);


                if (response.data.status == 400) {
                    toast.error(response.message);

                } else {
                    toast.success(response.data.message);
                    handlerCancelSideNav();
                }

            } catch (error) { }
        }

    }
    const handlerBackSideNav = () => {

        setBackButton(true)

        if (continueChange == 'createStep') {
            setContinueChange('suggets')
        }
    }

    const handlerCustomReminder = (isChecked) => {
        setContinueChange('createStep')
        setCheckCustomReminder(isChecked);
    }
    const suggestedReminderAdd = (data) => {
        setReminderData(data)
    }
    const buttonShowingBackWith = (data) => {
        setBackButton(data)
    }
    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="row displayMobileReminders">
                        <div className="col-lg-5 col-md-12 col-sm-12 pl-0">
                            <h5 className="mb-2 text-titlecase mb-4">Reminders</h5>

                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext pr-0 pl-0">

                            <div className=" top-text-head">

                                <div className="reminder-top-button">


                                    <div className="remindersTapsButtons">
                                        {isConnected ? (
                                            // Show "Disconnect" button if user is connected
                                            <button className='outline reminder-active' onClick={() => handleConnectGoogleCalendar('sign-out')}>
                                                <img src={google_calendar_icon} width="25px" alt="disconnect" />
                                                Disconnect From Google Calendar
                                            </button>
                                        ) : (
                                            // Show "Connect" button if user is not connected
                                            <button className='outline' onClick={() => handleConnectGoogleCalendar('sign-in')}>
                                                <img src={google_calendar_icon} width="25px" alt="connect" />
                                                Connect With Google Calendar
                                            </button>
                                        )}
                                        <button className={tabSelection == 'calendar' ? 'outline reminder-active' : 'outline'} onClick={() => { setTabSelection('calendar') }}>
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 1.00195L9.5 1.00195L9.5 0.501954C9.5 0.369346 9.44732 0.242169 9.35355 0.148401C9.25979 0.0546323 9.13261 0.00195392 9 0.00195391C8.86739 0.0019539 8.74021 0.0546323 8.64645 0.1484C8.55268 0.242169 8.5 0.369346 8.5 0.501954L8.5 1.00195L3.5 1.00195L3.5 0.501953C3.5 0.369345 3.44732 0.242168 3.35355 0.1484C3.25979 0.0546318 3.13261 0.0019534 3 0.00195339C2.86739 0.00195338 2.74021 0.0546318 2.64645 0.1484C2.55268 0.242168 2.5 0.369345 2.5 0.501953L2.5 1.00195L1 1.00195C0.734783 1.00195 0.480429 1.10731 0.292893 1.29485C0.105357 1.48238 -1.5166e-07 1.73674 -1.74846e-07 2.00195L-1.04907e-06 12.002C-1.07226e-06 12.2672 0.105356 12.5215 0.292892 12.7091C0.480428 12.8966 0.734782 13.002 0.999999 13.002L11 13.002C11.2652 13.002 11.5196 12.8966 11.7071 12.7091C11.8946 12.5215 12 12.2672 12 12.002L12 2.00195C12 1.73674 11.8946 1.48238 11.7071 1.29485C11.5196 1.10731 11.2652 1.00195 11 1.00195ZM2.5 2.00195L2.5 2.50195C2.5 2.63456 2.55268 2.76174 2.64645 2.85551C2.74021 2.94927 2.86739 3.00195 3 3.00195C3.13261 3.00195 3.25978 2.94927 3.35355 2.85551C3.44732 2.76174 3.5 2.63456 3.5 2.50195L3.5 2.00195L8.5 2.00195L8.5 2.50195C8.5 2.63456 8.55268 2.76174 8.64645 2.85551C8.74021 2.94928 8.86739 3.00195 9 3.00195C9.13261 3.00195 9.25979 2.94928 9.35355 2.85551C9.44732 2.76174 9.5 2.63456 9.5 2.50195L9.5 2.00195L11 2.00195L11 4.00195L1 4.00195L1 2.00195L2.5 2.00195ZM11 12.002L0.999999 12.002L1 5.00195L11 5.00195L11 12.002Z" fill="#00487C" />
                                            </svg>
                                            Calendar
                                        </button>

                                        <button className={tabSelection == 'table' ? 'outline reminder-active' : 'outline'} onClick={() => { setTabSelection('table') }}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 3.00195L2 3.00195C1.86739 3.00195 1.74021 3.05463 1.64645 3.1484C1.55268 3.24217 1.5 3.36934 1.5 3.50195L1.5 12.002C1.5 12.2672 1.60536 12.5215 1.79289 12.7091C1.98043 12.8966 2.23478 13.002 2.5 13.002L13.5 13.002C13.7652 13.002 14.0196 12.8966 14.2071 12.7091C14.3946 12.5215 14.5 12.2672 14.5 12.002L14.5 3.50195C14.5 3.36935 14.4473 3.24217 14.3536 3.1484C14.2598 3.05463 14.1326 3.00195 14 3.00195ZM2.5 7.00195L5 7.00195L5 9.00195L2.5 9.00195L2.5 7.00195ZM6 7.00195L13.5 7.00195L13.5 9.00195L6 9.00195L6 7.00195ZM13.5 4.00195L13.5 6.00195L2.5 6.00195L2.5 4.00195L13.5 4.00195ZM2.5 10.002L5 10.002L5 12.002L2.5 12.002L2.5 10.002ZM13.5 12.002L6 12.002L6 10.002L13.5 10.002L13.5 12.002Z" fill="#85898F" />
                                            </svg>
                                            Table
                                        </button>
                                    </div>

                                    <button className="filled-btn" onClick={handlerReminderSideNave}>

                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00065 3.33594V12.6693M3.33398 8.0026H12.6673" stroke="white" strokeLinecap="round" />
                                        </svg>

                                        Add Reminder
                                    </button>

                                </div>


                            </div>
                        </div>
                        <div className="col-md-12 p-0">
                            <InputGroup className='search-field '>
                                <InputGroup.Text id="basic-addon1"><img src={MagnifyingGlass} /></InputGroup.Text>
                                <Form.Control type='text' placeholder='Search ' />
                            </InputGroup>
                        </div>

                        {tabSelection == 'calendar' ?
                            <div className="row  dashboard-card-parent">
                                <div className="row">

                                    <div className="col-md-12 col-sm-12">

                                        <RemindersCalender />
                                    </div>
                                </div>

                            </div>
                            :

                            <div className=" Reminder-dashboard-card-parent">
                                <div className="row">

                                    <div className="col-md-12 col-sm-12">

                                        <RemindersTable />
                                    </div>
                                </div>

                            </div>
                        }

                    </div>
                </div>
            </div>

            <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

            <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

                {continueChange == 'suggets' ? <ReminderSideNav buttonShowingBackWith={buttonShowingBackWith} backButton={backButton} suggestedReminderAdd={suggestedReminderAdd} handlerCustomReminder={handlerCustomReminder} /> : continueChange == 'createStep' ? <ReminderSideNavCreate suggestedReminderAdd={suggestedReminderAdd} buttonShowingBackWith={buttonShowingBackWith} backButton={backButton} /> : <ReminderSideNavEdit />}


                <div className='side-footer'>
                    <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>
                    {reminderData ?
                        <>
                            <button className='footer-button-primary ' onClick={handlerChangeStep}>{continueChange != 'suggets' ? 'Save' : 'Add Reminder'}</button>
                            <button className='footer-button-cancel footer-button-back' onClick={handlerBackSideNav}>Back</button>
                        </>
                        :
                        continueChange == 'createStep' ?
                            <>
                                <button className='footer-button-primary ' onClick={handlerChangeStep}>{continueChange != 'suggets' ? 'Save' : 'Add Reminder'}</button>
                                <button className='footer-button-cancel footer-button-back' onClick={handlerBackSideNav}>Back</button>
                            </>
                            : ''

                    }



                </div>



            </div>
        </div>


    );
};

export default Reminders;
