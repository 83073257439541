import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import plusIcon from '../../asstes/dash-icons/plusIcon.svg'
import { Select } from './Select';
import LinkReminders from './linkReminders';
import { fetchHomeEssentials, getProjectsList, getPurchaseItem, getServiceItem } from '../../../axiosCalls/userdashboard';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';

const PurchasesSideNavEdit = ({ selectedId, itemType, updatePurchaseData, updatedServiceData }) => {
    const [startDate, setStartDate] = useState(new Date());
    const [warrantyCheck, setWarrantyCheck] = useState('unchecked');
    const [purchaseData, setPurchaseData] = useState({
        files: {
            receipt: null,
            files: null,
            warrantyFiles: null
        }
    }); // State for storing the fetched purchase data
    const [serviceData, setServiceData] = useState({
        files: {
            receipt: null,
            files: null,
            warrantyFiles: null
        }
    }); // State for storing the service data
    const [updatedData, setUpdatedData] = useState(null); // State for storing the updated data
    const [listOfReceipts, setListOfReceipts] = useState([]);
    const [homeEssentials, setHomeEssentials] = useState([]); // State for home essentials
    const [selectedHomeEssentials, setSelectedHomeEssentials] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [listOfFiles, setListOfFiles] = useState([]);
    const [listOfWarrantyFiles, setListOfWarrantyFiles] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (itemType === 'purchase') {
                    const res = await getPurchaseItem(selectedId);
                    setPurchaseData(res.data); // Set the purchase data
                    setServiceData(null); // Clear service data
                    setUpdatedData(res.data); // Update the updatedData state
                    setListOfReceipts(res.data.receipt_url);
                    setListOfFiles(res.data.uploaded_files);
                    setListOfWarrantyFiles(res.data.warranty_data.files);
                    if (res.data.linked_homeessential) {
                        setSelectedHomeEssentials(JSON.parse(res.data.linked_homeessential))

                    }
                    if (res.data.warranty) {
                        setWarrantyCheck('checked')
                    }
                } else if (itemType === 'service') {
                    const resservice = await getServiceItem(selectedId);
                    setServiceData(resservice.data); // Set the service data
                    setPurchaseData(null); // Clear purchase data
                    setUpdatedData(resservice.data); // Update the updatedData state
                    setListOfReceipts(resservice.data.receipt_url);
                    setListOfFiles(resservice.data.uploaded_files);
                    setListOfWarrantyFiles(resservice.data.warranty_data.files);
                    if (resservice.data.linked_homeessential) {
                        setSelectedHomeEssentials(JSON.parse(resservice.data.linked_homeessential))

                    }
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, [selectedId, itemType]);

    const handlerWarrantyChecked = () => {

        if (warrantyCheck === 'unchecked') {
            setWarrantyCheck('checked');
        } else {
            setWarrantyCheck('unchecked');

        }

    }
    const handlePurchaseFileChange = (event) => {
        const file = event.target.files[0];
        const fileName = event.target.name;

        if (fileName === 'receipt') {
            setListOfReceipts([...listOfReceipts, file]);
            setPurchaseData((prevData) => ({ ...prevData, files: { ...prevData.files, receipt: [...listOfReceipts, file] } }));
        } else if (fileName === 'files') {
            setListOfFiles([...listOfFiles, file]);
            setPurchaseData((prevData) => ({ ...prevData, files: { ...prevData.files, files: [...listOfFiles, file] } }));
        } else if (fileName === 'warrantyFiles') {
            setListOfWarrantyFiles([...listOfWarrantyFiles, file]);
            setPurchaseData((prevData) => ({ ...prevData, files: { ...prevData.files, warrantyFiles: [...listOfWarrantyFiles, file] } }));
        }
    }
    const handleServiceFileChange = (event) => {
        const file = event.target.files[0];
        const fileName = event.target.name;
        if (fileName === 'receipt') {
            setListOfReceipts([...listOfReceipts, file]);
            setServiceData({ ...serviceData, files: { ...serviceData.files, [fileName]: [...listOfReceipts, file] } });
        } else if (fileName === 'files') {
            setListOfFiles([...listOfFiles, file]);
            setServiceData({ ...serviceData, files: { ...serviceData.files, [fileName]: [...listOfFiles, file] } });
        } else if (fileName === 'warrantyFiles') {
            setListOfWarrantyFiles([...listOfWarrantyFiles, file]);
            setServiceData({ ...serviceData, files: { ...serviceData.files, [fileName]: [...listOfWarrantyFiles, file] } });
        }
    }
    const getFileIcon = (file) => {
        if (file instanceof File) {
            // Handle files
            if (file.type === 'application/pdf') {
                return pdfIcon;  // Assuming `pdfIcon` is defined
            } else {
                return URL.createObjectURL(file); // Get object URL for image files
            }
        } else {
            const fileExtension = file.split('.').pop().toLowerCase(); // Get the extension from the URL
            if (fileExtension === 'pdf') {
                return pdfIcon;  // Return the PDF icon if it's a PDF URL
            } else {
                return file;  // Return the URL itself for image files
            }
        }
    };
    // Callback function to get reminders from the child component
    const handleRemindersUpdate = (reminders) => {

        if (itemType === 'purchase') {
            setPurchaseData((prevData) => ({ ...prevData, 'linked_reminders': reminders }));
        } else {
            setServiceData((prevData) => ({ ...prevData, 'linked_reminders': reminders }));
        }

    };
    // Fetching home essentials data
    useEffect(() => {
        const fetchEssentials = async () => {
            try {
                const response = await fetchHomeEssentials(); // Assuming this function returns the home essentials data
                const optionsData = response.data.items.map(item => ({
                    label: `${item.type} - ${item.model_number}`, // Display type and model number as label
                    value: item.id, // ID as value
                }));
                setHomeEssentials(optionsData);
            } catch (error) {
                console.error("Error fetching home essentials:", error);
            }
        };

        const fetchProjectList = async () => {
            try {
                const response = await getProjectsList();
                const projectOptions = response.data.map(project => ({
                    value: project.ID,
                    label: project.title
                }));
                // Update the state with the project options
                setProjectOptions(projectOptions);
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        fetchProjectList();
        fetchEssentials();
    }, []);
    // Handle home essential selection change
    const handleHomeEssentialChange = (selected) => {
        setSelectedHomeEssentials(selected);
        if (itemType === 'purchase') {
            setPurchaseData({ ...purchaseData, linked_homeessential: selected })
        } else {
            setServiceData({ ...serviceData, linked_homeessential: selected })

        }

    };
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {

        setIsChecked(event.target.checked);

    };

    console.log(purchaseData);
    updatePurchaseData(purchaseData);
    updatedServiceData(serviceData);
    return (

        <div className='side-nav'>
            <div className='side-header'>
                <h3>Edit Purchase/Service</h3>

            </div>
            <div className='side-body purchesServieceSideNav'>
                <Form>

                    {itemType === 'purchase' && purchaseData ?

                        <Row className="mb-3">



                            <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Product Name</Form.Label>
                                <Form.Control type="text" placeholder="Furnace" value={purchaseData.product_name} onChange={(e) => setPurchaseData({ ...purchaseData, product_name: e.target.value })} />
                            </Form.Group>

                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Label>Make </Form.Label>
                                <Form.Control type="text" placeholder="Goodman G350" value={purchaseData.make} onChange={(e) => setPurchaseData({ ...purchaseData, make: e.target.value })} />

                            </Form.Group>

                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Label>Model Number </Form.Label>
                                <Form.Control type="text" placeholder="GPSPE-905" value={purchaseData.model_number} onChange={(e) => setPurchaseData({ ...purchaseData, model_number: e.target.value })} />

                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Label>Purchase date</Form.Label>
                                <Form.Control type="date" placeholder="Name" value={purchaseData.purchase_date} onChange={(e) => setPurchaseData({ ...purchaseData, purchase_date: e.target.value })} />

                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-3 position-relative">
                                <Form.Label>Amount</Form.Label>

                                <Form.Control type="text" placeholder="4,434.32" value={purchaseData.amount} onChange={(e) => setPurchaseData({ ...purchaseData, amount: e.target.value })} />
                                <span className='amount-icon'>
                                    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 6.5L5.5 6.5L5.5 2.5L6 2.5C6.53043 2.5 7.03914 2.71072 7.41421 3.08579C7.78929 3.46086 8 3.96957 8 4.5C8 4.63261 8.05268 4.75979 8.14644 4.85356C8.24021 4.94732 8.36739 5 8.5 5C8.63261 5 8.75978 4.94732 8.85355 4.85356C8.94732 4.75979 9 4.63261 9 4.5C8.99917 3.70461 8.68284 2.94203 8.12041 2.3796C7.55798 1.81717 6.79539 1.50083 6 1.5L5.5 1.5L5.5 0.500001C5.5 0.367393 5.44732 0.240216 5.35355 0.146448C5.25979 0.0526796 5.13261 1.21499e-06 5 1.18021e-06C4.86739 1.14543e-06 4.74021 0.0526795 4.64645 0.146448C4.55268 0.240216 4.5 0.367393 4.5 0.500001L4.5 1.5L4 1.5C3.20435 1.5 2.44129 1.81607 1.87868 2.37868C1.31607 2.94129 0.999999 3.70435 0.999999 4.5C0.999999 5.29565 1.31607 6.05871 1.87868 6.62132C2.44129 7.18393 3.20435 7.5 4 7.5L4.5 7.5L4.5 11.5L3.5 11.5C2.96956 11.5 2.46086 11.2893 2.08578 10.9142C1.71071 10.5391 1.5 10.0304 1.5 9.5C1.5 9.36739 1.44732 9.24021 1.35355 9.14645C1.25978 9.05268 1.13261 9 0.999998 9C0.867389 9 0.740213 9.05268 0.646444 9.14645C0.552676 9.24021 0.499998 9.36739 0.499998 9.5C0.500825 10.2954 0.817161 11.058 1.37959 11.6204C1.94202 12.1828 2.7046 12.4992 3.5 12.5L4.5 12.5L4.5 13.5C4.5 13.6326 4.55267 13.7598 4.64644 13.8536C4.74021 13.9473 4.86739 14 5 14C5.1326 14 5.25978 13.9473 5.35355 13.8536C5.44732 13.7598 5.5 13.6326 5.5 13.5L5.5 12.5L6.5 12.5C7.29565 12.5 8.05871 12.1839 8.62132 11.6213C9.18393 11.0587 9.5 10.2957 9.5 9.5C9.5 8.70435 9.18393 7.94129 8.62132 7.37868C8.05871 6.81607 7.29565 6.5 6.5 6.5ZM4 6.5C3.46957 6.5 2.96086 6.28929 2.58578 5.91421C2.21071 5.53914 2 5.03043 2 4.5C2 3.96957 2.21071 3.46086 2.58579 3.08579C2.96086 2.71071 3.46957 2.5 4 2.5L4.5 2.5L4.5 6.5L4 6.5ZM6.5 11.5L5.5 11.5L5.5 7.5L6.5 7.5C7.03043 7.5 7.53914 7.71072 7.91421 8.08579C8.28928 8.46086 8.5 8.96957 8.5 9.5C8.5 10.0304 8.28928 10.5391 7.91421 10.9142C7.53914 11.2893 7.03043 11.5 6.5 11.5Z" fill="#535860" />
                                    </svg>
                                </span>
                            </Form.Group>

                            <Form.Group md="12" className='mb-3'>
                                <div className='files-choosen'>
                                    <div className='title'>Add Receipt</div>

                                    <div className='button'>
                                        <div className="outline-yellow-button">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                            </svg>
                                            Add file
                                            <input type='file' name='receipt' multiple accept='.png,.pdf,.jpg,.jpeg' className='chooeseFile' onChange={handlePurchaseFileChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className='listOfImages'>
                                    {listOfReceipts?.map((file, index) => (
                                        <div className='image-choosed' key={index}>
                                            <a href={file instanceof File ? URL.createObjectURL(file) : file} target='_blank' rel='noopener noreferrer'>
                                                <img src={getFileIcon(file)} alt='Image file' />
                                            </a>
                                            <button
                                                onClick={() => {
                                                    const updatedList = listOfReceipts.filter((f, i) => i !== index);
                                                    setListOfReceipts(updatedList);
                                                    setPurchaseData({ ...purchaseData, receipt_url: { ...purchaseData.files, receipt_url: updatedList } });

                                                }}
                                                className='image-choosed-crossed'
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </ Form.Group>
                            <Form.Group md="12" className='mb-3'>
                                <div className='files-choosen'>
                                    <div className='title'>Files</div>

                                    <div className='button'>
                                        <div className="outline-yellow-button">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                            </svg>
                                            Add file
                                            <input type='file' name='files' multiple accept='.png,.pdf,.jpg,.jpeg' className='chooeseFile' onChange={handlePurchaseFileChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className='listOfImages'>
                                    {listOfFiles?.map((file, index) => (
                                        <div className='image-choosed' key={index}>
                                            <a href={file instanceof File ? URL.createObjectURL(file) : file} target='_blank' rel='noopener noreferrer'>
                                                <img src={getFileIcon(file)} alt='Image file' />
                                            </a>
                                            <button
                                                onClick={() => {
                                                    const updatedList = listOfFiles.filter((f, i) => i !== index);
                                                    setListOfFiles(updatedList);
                                                    setPurchaseData({ ...purchaseData, files: { ...purchaseData.files, files: updatedList } });

                                                }}
                                                className='image-choosed-crossed'
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>
                            <Form.Group md="12" className="mb-3">
                                <div className='link-table-main'>

                                    <Form.Label>Linked Reminders</Form.Label>
                                    <LinkReminders type="custom" onRemindersUpdate={handleRemindersUpdate} />
                                </div>


                            </Form.Group>
                            {/* Linked Home Essential */}
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Linked Home Essential</Form.Label>
                                <Select
                                    multiple
                                    options={homeEssentials} // Updated options to use home essentials from API
                                    value={selectedHomeEssentials}
                                    onChange={handleHomeEssentialChange} // Use the dedicated handler
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Project</Form.Label>
                                <Form.Select value={purchaseData.project} onChange={(e) => setPurchaseData({ ...purchaseData, project: e.target.value })}>
                                    <option value="">Select Project</option>
                                    {projectOptions.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Notes</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder='Type here...' value={purchaseData.notes} onChange={(e) => setPurchaseData({ ...purchaseData, notes: e.target.value })} />
                            </Form.Group>
                            <Form.Group md="12" className='mb-3'>
                                <div className='files-choosen warrantyAddArea'>
                                    <div className='title'>Warranty</div>

                                    <div className='button'>
                                        <label className="switch">
                                            <input type="checkbox"
                                                checked={purchaseData && purchaseData.warranty ? purchaseData.warranty : false}
                                                onChange={() => setPurchaseData({ ...purchaseData, warrantyCheck: purchaseData.warranty ? true : false })}

                                            />
                                            <span className="slider round"></span>
                                        </label>

                                    </div>

                                </div>
                                <div className='warrantyForm'>
                                    {purchaseData && purchaseData.warranty ?
                                        <div className='warrantyAddInnerArea'>
                                            <Row className="mb-3">

                                                <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Name"
                                                        value={purchaseData.warranty_data[0].name}
                                                        onChange={(e) => {
                                                            const updatedWarrantyData = {
                                                                ...purchaseData.warranty_data,
                                                                0: {
                                                                    ...purchaseData.warranty_data[0],
                                                                    name: e.target.value,
                                                                },
                                                            };
                                                            setPurchaseData({ ...purchaseData, warranty_data: updatedWarrantyData });
                                                        }}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" className="mb-3">
                                                    <Form.Label>Number</Form.Label>
                                                    <Form.Control type="text" placeholder="Number"
                                                        value={purchaseData.warranty_data[1].number}
                                                        onChange={(e) => {
                                                            const updatedWarrantyData = {
                                                                ...purchaseData.warranty_data,
                                                                1: {
                                                                    ...purchaseData.warranty_data[1],
                                                                    number: e.target.value,
                                                                },
                                                            };
                                                            setPurchaseData({ ...purchaseData, warranty_data: updatedWarrantyData });
                                                        }}
                                                    />


                                                </Form.Group>

                                                <Form.Group md="12" className='mb-3'>
                                                    <div className='files-choosen'>
                                                        <div className='title'>Files</div>

                                                        <div className='button'>
                                                            <div className="outline-yellow-button">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                                                </svg>
                                                                Add file
                                                                <input type='file' name='warrantyFiles' accept='.png,.pdf,.jpg,.jpeg' multiple className='chooeseFile' onChange={handlePurchaseFileChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='listOfImages'>
                                                        {listOfWarrantyFiles?.map((file, index) => (
                                                            <div className='image-choosed' key={index}>
                                                                <a href={file instanceof File ? URL.createObjectURL(file) : file} target='_blank' rel='noopener noreferrer'>
                                                                    <img src={getFileIcon(file)} alt='Image file' />
                                                                </a>
                                                                <button
                                                                    onClick={() => {
                                                                        const updatedList = listOfWarrantyFiles.filter((f, i) => i !== index);
                                                                        setListOfWarrantyFiles(updatedList);
                                                                        setPurchaseData({ ...purchaseData, files: { ...purchaseData.files, warrantyFiles: updatedList } });

                                                                    }}
                                                                    className='image-choosed-crossed'
                                                                >
                                                                    &times;
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} md="6" className="mb-3">
                                                    <Form.Label>Start </Form.Label>
                                                    <Form.Control type="date" placeholder="Name"
                                                        value={purchaseData.warranty_data[2].start_date}
                                                        onChange={(e) => {
                                                            const updatedWarrantyData = {
                                                                ...purchaseData.warranty_data,
                                                                2: {
                                                                    ...purchaseData.warranty_data[2],
                                                                    start_date: e.target.value,
                                                                },
                                                            };
                                                            setPurchaseData({ ...purchaseData, warranty_data: updatedWarrantyData });
                                                        }}
                                                    />

                                                </Form.Group>
                                                <Form.Group as={Col} md="6" className="mb-3">
                                                    <Form.Label>End</Form.Label>
                                                    <Form.Control type="date" placeholder="Name"
                                                        value={purchaseData.warranty_data[3].end_date}
                                                        onChange={(e) => {
                                                            const updatedWarrantyData = {
                                                                ...purchaseData.warranty_data,
                                                                3: {
                                                                    ...purchaseData.warranty_data[3],
                                                                    end_date: e.target.value,
                                                                },
                                                            };
                                                            setPurchaseData({ ...purchaseData, warranty_data: updatedWarrantyData });
                                                        }}
                                                    />

                                                </Form.Group>
                                            </Row>
                                            <Form.Group as={Col} className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Notes</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder='Type here...'
                                                    value={purchaseData.warranty_data[4].notes}
                                                    onChange={(e) => {
                                                        const updatedWarrantyData = {
                                                            ...purchaseData.warranty_data,
                                                            4: {
                                                                ...purchaseData.warranty_data[4],
                                                                notes: e.target.value,
                                                            },
                                                        };
                                                        setPurchaseData({ ...purchaseData, warranty_data: updatedWarrantyData });
                                                    }}

                                                />
                                            </Form.Group>

                                        </div>
                                        : ''}
                                </div>


                            </Form.Group>
                        </Row>
                        : serviceData ?

                            <Row className="mb-3">

                                <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Service Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Furnace"
                                        value={serviceData.service_name || ''} // Setting the value here
                                        onChange={(e) => setServiceData({ ...serviceData, service_name: e.target.value })}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Vendor</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Jordane Enterprises"
                                        value={serviceData.vendor || ''}  // Setting the value here
                                        onChange={(e) => setServiceData({ ...serviceData, vendor: e.target.value })}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="6" className="mb-3">
                                    <Form.Label>Service Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Name"
                                        value={serviceData.service_date || ''}  // Setting the value here
                                        onChange={(e) => setServiceData({ ...serviceData, service_date: e.target.value })}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="6" className="mb-3 position-relative">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="4,434.32"
                                        value={serviceData.amount || ''}  // Setting the value here
                                        onChange={(e) => setServiceData({ ...serviceData, amount: e.target.value })}
                                    />
                                    <span className='amount-icon'><svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 6.5L5.5 6.5L5.5 2.5L6 2.5C6.53043 2.5 7.03914 2.71072 7.41421 3.08579C7.78929 3.46086 8 3.96957 8 4.5C8 4.63261 8.05268 4.75979 8.14644 4.85356C8.24021 4.94732 8.36739 5 8.5 5C8.63261 5 8.75978 4.94732 8.85355 4.85356C8.94732 4.75979 9 4.63261 9 4.5C8.99917 3.70461 8.68284 2.94203 8.12041 2.3796C7.55798 1.81717 6.79539 1.50083 6 1.5L5.5 1.5L5.5 0.500001C5.5 0.367393 5.44732 0.240216 5.35355 0.146448C5.25979 0.0526796 5.13261 1.21499e-06 5 1.18021e-06C4.86739 1.14543e-06 4.74021 0.0526795 4.64645 0.146448C4.55268 0.240216 4.5 0.367393 4.5 0.500001L4.5 1.5L4 1.5C3.20435 1.5 2.44129 1.81607 1.87868 2.37868C1.31607 2.94129 0.999999 3.70435 0.999999 4.5C0.999999 5.29565 1.31607 6.05871 1.87868 6.62132C2.44129 7.18393 3.20435 7.5 4 7.5L4.5 7.5L4.5 11.5L3.5 11.5C2.96956 11.5 2.46086 11.2893 2.08578 10.9142C1.71071 10.5391 1.5 10.0304 1.5 9.5C1.5 9.36739 1.44732 9.24021 1.35355 9.14645C1.25978 9.05268 1.13261 9 0.999998 9C0.867389 9 0.740213 9.05268 0.646444 9.14645C0.552676 9.24021 0.499998 9.36739 0.499998 9.5C0.500825 10.2954 0.817161 11.058 1.37959 11.6204C1.94202 12.1828 2.7046 12.4992 3.5 12.5L4.5 12.5L4.5 13.5C4.5 13.6326 4.55267 13.7598 4.64644 13.8536C4.74021 13.9473 4.86739 14 5 14C5.1326 14 5.25978 13.9473 5.35355 13.8536C5.44732 13.7598 5.5 13.6326 5.5 13.5L5.5 12.5L6.5 12.5C7.29565 12.5 8.05871 12.1839 8.62132 11.6213C9.18393 11.0587 9.5 10.2957 9.5 9.5C9.5 8.70435 9.18393 7.94129 8.62132 7.37868C8.05871 6.81607 7.29565 6.5 6.5 6.5ZM4 6.5C3.46957 6.5 2.96086 6.28929 2.58578 5.91421C2.21071 5.53914 2 5.03043 2 4.5C2 3.96957 2.21071 3.46086 2.58579 3.08579C2.96086 2.71071 3.46957 2.5 4 2.5L4.5 2.5L4.5 6.5L4 6.5ZM6.5 11.5L5.5 11.5L5.5 7.5L6.5 7.5C7.03043 7.5 7.53914 7.71072 7.91421 8.08579C8.28928 8.46086 8.5 8.96957 8.5 9.5C8.5 10.0304 8.28928 10.5391 7.91421 10.9142C7.53914 11.2893 7.03043 11.5 6.5 11.5Z" fill="#535860" />
                                    </svg></span>
                                </Form.Group>


                                <Form.Group md="12" className='mb-3'>
                                    <div className='files-choosen'>
                                        <div className='title'>Add Receipt</div>

                                        <div className='button'>
                                            <div className="outline-yellow-button">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                                </svg>
                                                Add file
                                                <input type='file' name='receipt' multiple accept='.png,.pdf,.jpg,.jpeg' className='chooeseFile' onChange={handleServiceFileChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='listOfImages'>
                                        {listOfReceipts?.map((file, index) => (
                                            <div className='image-choosed' key={index}>
                                                <a href={file instanceof File ? URL.createObjectURL(file) : file} target='_blank' rel='noopener noreferrer'>
                                                    <img src={getFileIcon(file)} alt='Image file' />
                                                </a>
                                                <button
                                                    onClick={() => {
                                                        const updatedList = listOfReceipts.filter((f, i) => i !== index);
                                                        setListOfReceipts(updatedList);
                                                        setServiceData({ ...serviceData, files: { ...serviceData.files, receipt: updatedList } });

                                                    }}
                                                    className='image-choosed-crossed'
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>

                                <Form.Group md=" 12" className='mb-3'>
                                    <div className='files-choosen'>
                                        <div className='title'>Files</div>

                                        <div className='button'>
                                            <div className="outline-yellow-button">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                                </svg>
                                                Add file
                                                <input type='file' name='files' multiple accept='.png,.pdf,.jpg,.jpeg' className='chooeseFile' onChange={handleServiceFileChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='listOfImages'>
                                        {listOfFiles?.map((file, index) => (
                                            <div className='image-choosed' key={index}>
                                                <a href={file instanceof File ? URL.createObjectURL(file) : file} target='_blank' rel='noopener noreferrer'>
                                                    <img src={getFileIcon(file)} alt='Image file' />
                                                </a>
                                                <button
                                                    onClick={() => {
                                                        const updatedList = listOfFiles.filter((f, i) => i !== index);
                                                        setListOfFiles(updatedList);
                                                        setServiceData({ ...serviceData, files: { ...serviceData.files, files: updatedList } });

                                                    }}
                                                    className='image-choosed-crossed'
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>
                                <Form.Group md="12" className="mb-3">
                                    <div className='link-table-main'>

                                        <Form.Label>Linked Reminders</Form.Label>
                                        <LinkReminders type='custom' onRemindersUpdate={handleRemindersUpdate} />
                                    </div>


                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Linked Home Essential</Form.Label>
                                    <Select
                                        multiple
                                        options={homeEssentials} // Updated options to use home essentials from API
                                        value={selectedHomeEssentials}
                                        onChange={handleHomeEssentialChange} // Use the dedicated handler
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Project</Form.Label>
                                    <Form.Select onChange={(e) => setServiceData({ ...serviceData, project: e.target.value })}>
                                        {projectOptions.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder='Type here...' onChange={(e) => setServiceData({ ...serviceData, notes: e.target.value })} />
                                </Form.Group>

                                <Form.Group md="12" className='mb-3'>
                                    <div className='files-choosen warrantyAddArea'>
                                        <div className='title'>Warranty</div>

                                        <div className='button'>
                                            <label className="switch">
                                                <input type="checkbox"
                                                    checked={serviceData && serviceData.warranty ? serviceData.warranty : false}
                                                    onChange={() => setServiceData({ ...serviceData, warrantyCheck: serviceData.warranty ? true : false })}

                                                />
                                                <span className="slider round"></span>
                                            </label>

                                        </div>

                                    </div>
                                    <div className='warrantyForm'>
                                        {serviceData && serviceData.warranty ?
                                            <div className='warrantyAddInnerArea'>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Name"
                                                            value={serviceData.warranty_data[0].name}
                                                            onChange={(e) => {
                                                                const updatedWarrantyData = {
                                                                    ...serviceData.warranty_data,
                                                                    0: {
                                                                        ...serviceData.warranty_data[0],
                                                                        name: e.target.value,
                                                                    },
                                                                };
                                                                setServiceData({ ...serviceData, warranty_data: updatedWarrantyData });
                                                            }}
                                                        />                                                    </Form.Group>
                                                    <Form.Group as={Col} md="12" className="mb-3">
                                                        <Form.Label>Number</Form.Label>
                                                        <Form.Control type="text" placeholder="Number"
                                                            value={serviceData.warranty_data[1].number}
                                                            onChange={(e) => {
                                                                const updatedWarrantyData = {
                                                                    ...serviceData.warranty_data,
                                                                    1: {
                                                                        ...serviceData.warranty_data[1],
                                                                        number: e.target.value,
                                                                    },
                                                                };
                                                                setServiceData({ ...serviceData, warranty_data: updatedWarrantyData });
                                                            }}
                                                        />


                                                    </Form.Group>

                                                    <Form.Group md="12" className='mb-3'>
                                                        <div className='files-choosen'>
                                                            <div className='title'>Files</div>

                                                            <div className='button'>
                                                                <div className="outline-yellow-button">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                        <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                                                    </svg>
                                                                    Add file
                                                                    <input type='file' accept='.png,.pdf,.jpg,.jpeg' name='warrantyFiles' multiple className='chooeseFile' onChange={handleServiceFileChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='listOfImages'>
                                                            {listOfWarrantyFiles?.map((file, index) => (
                                                                <div className='image-choosed' key={index}>
                                                                    <a href={file instanceof File ? URL.createObjectURL(file) : file} target='_blank' rel='noopener noreferrer'>
                                                                        <img src={getFileIcon(file)} alt='Image file' />
                                                                    </a>
                                                                    <button
                                                                        onClick={() => {
                                                                            const updatedList = listOfWarrantyFiles.filter((f, i) => i !== index);
                                                                            setListOfWarrantyFiles(updatedList);
                                                                            setServiceData({ ...serviceData, files: { ...serviceData.files, warrantyFiles: updatedList } });

                                                                        }}
                                                                        className='image-choosed-crossed'
                                                                    >
                                                                        &times;
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group as={Col} md="6" className="mb-3">
                                                        <Form.Label>Start </Form.Label>
                                                        <Form.Control type="date" placeholder="Name"
                                                            value={serviceData.warranty_data[2].start_date}
                                                            onChange={(e) => {
                                                                const updatedWarrantyData = {
                                                                    ...serviceData.warranty_data,
                                                                    2: {
                                                                        ...serviceData.warranty_data[2],
                                                                        start_date: e.target.value,
                                                                    },
                                                                };
                                                                setServiceData({ ...serviceData, warranty_data: updatedWarrantyData });
                                                            }}
                                                        />

                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" className="mb-3">
                                                        <Form.Label>End</Form.Label>
                                                        <Form.Control type="date" placeholder="Name"
                                                            value={serviceData.warranty_data[3].end_date}
                                                            onChange={(e) => {
                                                                const updatedWarrantyData = {
                                                                    ...serviceData.warranty_data,
                                                                    3: {
                                                                        ...serviceData.warranty_data[3],
                                                                        end_date: e.target.value,
                                                                    },
                                                                };
                                                                setServiceData({ ...serviceData, warranty_data: updatedWarrantyData });
                                                            }}
                                                        />

                                                    </Form.Group>
                                                </Row>
                                                <Form.Group as={Col} className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>Notes</Form.Label>
                                                    <Form.Control as="textarea" rows={3} placeholder='Type here...'
                                                        value={serviceData.warranty_data[4].notes}
                                                        onChange={(e) => {
                                                            const updatedWarrantyData = {
                                                                ...serviceData.warranty_data,
                                                                4: {
                                                                    ...serviceData.warranty_data[4],
                                                                    notes: e.target.value,
                                                                },
                                                            };
                                                            setServiceData({ ...serviceData, warranty_data: updatedWarrantyData });
                                                        }}

                                                    />
                                                </Form.Group>

                                            </div>
                                            : ''}
                                    </div>


                                </Form.Group>
                            </Row>
                            : ''
                    }


                </Form>

            </div>
        </div>



    );
}

export default PurchasesSideNavEdit;
