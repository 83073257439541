import React, { useEffect, useState } from "react";
import DataTable, { Alignment, Direction } from "react-data-table-component";
import lampIcon from '../../asstes/dash-icons/sidebar/Lamp.svg'
import ReceiptIcon from '../../asstes/dash-icons/sidebar/Receipt.svg'
import BroomIcon from '../../asstes/dash-icons/sidebar/Broom.svg'
import PurchasesServiceSideView from "./purchasesServiceSideView";
import PurchasesSideNavEdit from "./purchasesSideNavEdit";
import { getPurchaseList, getServiceList, updatePurchaseItem, updateServiceItem } from "../../../axiosCalls/userdashboard";
import { toast } from "react-toastify";

const columns = [
  {
    name: "Type",
    selector: (row) => row.type,
    sortable: true,
    reorder: true,
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    reorder: true,
  },
  {
    name: "Make",
    selector: (row) => row.make,
    sortable: true,
    reorder: true,
  },
  {
    name: "Model #",
    selector: (row) => row.model,
    sortable: true,
    reorder: true,
  },
  {
    name: "Project",
    selector: (row) => row.project,
    sortable: true,
    reorder: true,
  },
  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
    reorder: true,
  },
  {
    name: "Cost",
    selector: (row) => row.cost,
    sortable: true,
    reorder: true,
  },
];


const PurchasesServicesTable = () => {
  const [sideNavWidth, setSideNavWidth] = useState('0px');
  const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
  const [continueChange, setContinueChange] = useState('viewStep');
  const [purchaseData, setPurchaseData] = useState([]); // State for storing the fetched purchase data
  const [serviceData, setServiceData] = useState([]); // State for storing the service data
  const [selectedID, setSelectedID] = useState(); // State for storing the service data
  const [itemType, setItemType] = useState(); // State for storing the service data
  const [purchaseDataU, setPurchaseDataU] = useState({});
  const [serviceDataU, setServiceDataU] = useState({});
  useEffect(() => {
    const fetchpurchaesdata = async () => {
      try {
        const res = await getPurchaseList();
        setPurchaseData(res.data); // Set the response data into state

        const resservice = await getServiceList();
        setServiceData(resservice.data);

      } catch (error) {
        console.error('Error fetching purchase data', error);
      }
    };

    fetchpurchaesdata();
  }, []);

  const handleRowSideNavOpen = (e) => {
    setSideNavWidth('sidenavPopuWidth');
    setsideNavWidthOverlay('100%');
    setContinueChange('viewStep');
    document.body.classList.add('no-scroll');
    setSelectedID(e.id)
    setItemType(e.itemTye);

  };

  const handlerCancelSideNav = () => {
    setSideNavWidth('sidenavPopuWidthHide');
    setsideNavWidthOverlay('0%');
    setContinueChange('viewStep');
    document.body.classList.remove('no-scroll');
  };
  const handlerUpdatePurchase = async () => {
    const { amount, files, project, linked_homeessential, linked_reminders, make, model_number, notes, product_name, purchase_date, warranty, receipt_url, uploaded_files, warranty_data } = purchaseDataU;
    let data = new FormData();
    data.append('product_name', product_name);
    data.append('make', make);
    data.append('model_number', model_number);
    data.append('purchase_date', purchase_date);
    data.append('amount', amount);
    linked_reminders.forEach(remind => {
      const reminderData = JSON.stringify({ id: remind.id, date: remind.start_date });
      data.append('linked_reminders[]', reminderData);
    });
    data.append('linked_homeessential', JSON.stringify(linked_homeessential));
    data.append('notes', notes);
    data.append('project', project);
    data.append('warranty', warranty);
    if (warranty) {
      data.append('warranty_data[][name]', warranty_data[0].name);
      data.append('warranty_data[][number]', warranty_data[1].number);
      data.append('warranty_data[][start_date]', warranty_data[2].start_date);
      data.append('warranty_data[][end_date]', warranty_data[3].end_date);
      data.append('warranty_data[][notes]', warranty_data[4].notes);

      if (files && files.warrantyFiles) {
        files.warrantyFiles.forEach(file => data.append('warranty_data_files[]', file));
      } else {
        warranty_data.files.forEach(file => data.append('warranty_data_files[]', file));
      }
    }



    if (files && files.receipt) {
      files.receipt.forEach(file => data.append('receipt[]', file));
    } else {
      receipt_url.forEach(file => data.append('receipt[]', file));

    }
    if (files && files.files) {
      files.files.forEach(file => data.append('files[]', file));
    } else {
      uploaded_files.forEach(file => data.append('files[]', file));

    }

    try {
      const res = await updatePurchaseItem(selectedID, data);
      if (res.status == 200) {
        toast.success(res.data.message);
        handlerCancelSideNav();
      }
    } catch (error) {
      console.log(error)
      toast.error(error);
    }

  }
  const handlerUpdateService = async () => {


    const { service_name, amount, files, project, linked_homeessential, linked_reminders, notes, service_date, vendor, receipt_url, uploaded_files, warranty, warranty_data } = serviceDataU;

    let data = new FormData();
    data.append('service_name', service_name);
    data.append('vendor', vendor);
    data.append('service_date', service_date);
    data.append('amount', amount);
    linked_reminders.forEach(remind => {
      const reminderData = JSON.stringify({ id: remind.id, date: remind.start_date });
      data.append('linked_reminders[]', reminderData);
    });
    data.append('project', project);
    data.append('notes', notes);
    data.append('linked_homeessential', JSON.stringify(linked_homeessential));
    data.append('warranty', warranty);
    if (warranty) {
      data.append('warranty_data[][name]', warranty_data[0].name);
      data.append('warranty_data[][number]', warranty_data[1].number);
      data.append('warranty_data[][start_date]', warranty_data[2].start_date);
      data.append('warranty_data[][end_date]', warranty_data[3].end_date);
      data.append('warranty_data[][notes]', warranty_data[4].notes);
      if (files && files.warrantyFiles) {
        files.warrantyFiles.forEach(file => data.append('warranty_data_files[]', file));
      } else {
        warranty_data.files.forEach(file => data.append('warranty_data_files[]', file));
      }
    }

    if (files && files.receipt) {
      files.receipt.forEach(file => data.append('receipt[]', file));
    } else {
      receipt_url.forEach(file => data.append('receipt[]', file));

    }
    if (files && files.files) {
      files.files.forEach(file => data.append('files[]', file));
    } else {
      uploaded_files.forEach(file => data.append('files[]', file));

    }


    try {
      const res = await updateServiceItem(selectedID, data);
      if (res.status == 200) {
        toast.success(res.data.message);
        handlerCancelSideNav();
      }
    } catch (error) {
      console.log(error)
      toast.error(error);
    }

  }

  const handlerChangeToEdit = async () => {
    setContinueChange('editStep');
    if (continueChange == 'editStep') {
      if (itemType === 'purchase') {
        handlerUpdatePurchase();
      }
      else {
        handlerUpdateService();
      }
    }
  };


  const updatePurchaseData = (data) => {
    setPurchaseDataU(data)
  }
  const updatedServiceData = (data) => {
    setServiceDataU(data)
  }

  // Dynamic data mapping
  const mapServiceData = (serviceData) => {
    return serviceData.map((item) => ({
      id: item.id,
      itemTye: 'service',
      type: (
        <span className={`services-badge type-camel`}>
          <img src={BroomIcon} alt="Service Icon" />
          Service
        </span>
      ),
      name: item.service_name,
      make: item.vendor,
      model: "", // No model number for services
      project: item.project, // Assuming project_id is project
      date: item.service_date,
      cost: `$${item.amount}`,
    }));
  };

  const mappedData = [...purchaseData.map((item) => ({
    id: item.id,
    type: (
      <span className={`services-badge type-green`}>
        <img src={lampIcon} alt="Type Icon" />
        Purchase
      </span>
    ),
    itemTye: 'purchase',
    name: item.product_name,
    make: item.make,
    model: item.model_number,
    project: item.property_id, // Assuming property_id is project
    date: item.purchase_date,
    cost: `$${item.amount}`,
  })), ...mapServiceData(serviceData)];

  return (
    <div className="PurcheseAndServicesTable">
      <DataTable
        columns={columns}
        data={mappedData} // Use the mapped data here
        defaultSortFieldId={6}
        selectableRows={false}
        onRowClicked={handleRowSideNavOpen} // Attach click event handler
        pagination={true}
        highlightOnHover={true}
      />
      <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>
      <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
        {continueChange === 'editStep' ? <PurchasesSideNavEdit itemType={itemType} selectedId={selectedID} updatedServiceData={updatedServiceData} updatePurchaseData={updatePurchaseData} /> : <PurchasesServiceSideView itemType={itemType} selectedId={selectedID} />}
        <div className="side-footer">
          <button className="footer-button-cancel" onClick={handlerCancelSideNav}>
            Cancel
          </button>
          <button className="footer-button-primary" onClick={handlerChangeToEdit}>
            {continueChange === 'editStep' ? 'Update' : 'Edit'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchasesServicesTable;

