import { axiosInstance } from "./axiosInstance";

export const getProperties = async () => {
    let res;
    try {
        res = await axiosInstance.get('/get-properties');

    } catch (error) {

    }
    return res ? res : "";


}

export const getPropertyById = async (id) => {
    let res;
    try {
        res = await axiosInstance.get(`/get-property/${id}`);

    } catch (error) {

    }
    return res ? res : "";


}

export const getStates = async () => {
    let res;
    try {
        res = await axiosInstance.get('/states');

    } catch (error) {

    }
    return res ? res : "";

}
export const getTaxonomyById = (taxonomyType, id) => {
    return axiosInstance.get(`/${taxonomyType}/${id}`);
};


export const getPropertyTypes = async () => {
    let res;
    try {
        res = await axiosInstance.get('/property-type');

    } catch (error) {

    }
    return res ? res : "";


}

export const getPropertyCategory = async () => {
    let res;
    try {
        res = await axiosInstance.get('/property-category');

    } catch (error) {

    }
    return res ? res : "";

}

export const addPropertyData = async (data) => {
    let res;
    try {
        res = await axiosInstance.post('/add-property', data);
    } catch (error) {
    }
    return res ? res : "";
}
export const updatePropertyData = async (data, id) => {
    let res;
    try {
        res = await axiosInstance.post(`/update-property/${id}`, data);
    } catch (error) {
    }
    return res ? res : "";
}
export const getSuggestedReminders = async () => {
    let res;
    try {
        res = await axiosInstance.get(`/get-suggested-reminders/`);
    } catch (error) { }
    return res ? res : "";
}
export const getCustomReminders = async () => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

        res = await axiosInstance.get(`/get-custom-reminders/?property_id=${propertyData.id}`);
    } catch (error) { }
    return res ? res : "";
}

export const listOfFrequencies = async () => {

    let res;
    try {
        res = await axiosInstance.get(`/frequency-terms/`);
    } catch (error) { }
    return res ? res : "";
}

export const addNotificationData = async (data, post_id) => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    data['property_id'] = propertyData.id;
    data['reminder_id'] = post_id;
    try {
        res = await axiosInstance.post('/add-event', data);
    } catch (error) {
        res = error
    }
    return res ? res : "";
}

export const addCustomReminder = async (data) => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    data['property_id'] = propertyData.id
    try {
        res = await axiosInstance.post('/add-reminder', data);
    } catch (error) {
        res = error
    }
    return res ? res : "";
}
export const updateCustomReminder = async (data) => {
    let res;
    try {
        res = await axiosInstance.put(`/update-reminder/${data.reminder_id}`, data);
    } catch (error) {
        res = error
    }
    return res ? res : "";
}

export const fetchHomeEssentials = async () => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    try {
        res = await axiosInstance.get(`/home-essentials?property_id=${propertyData.id}`);

    } catch (error) {
        res = error
    }
    return res ? res : "";
}
export const get_event_lists = async () => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    try {
        res = await axiosInstance.get(`/event-lists/?property_id=${propertyData.id}`);
    } catch (error) {
        res = error
    }
    return res ? res : "";
}

export const get_event_by_id = async (data) => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    try {
        res = await axiosInstance.get(`/event/${data}`);
    } catch (error) {
        res = error
    }
    return res ? res : "";
}
export const get_user_google_token = async (data) => {
    let res;

    try {
        res = await axiosInstance.get(`/google-authenticate/?code=${data}`);
    } catch (error) {
        res = error
    }
    return res ? res : "";
}
export const refresh_token_if_needed = async (refreshToken) => {
    let res;

    try {
        res = await axiosInstance.post(`/refresh-token/`);
    } catch (error) {
        res = error
    }
    return res ? res : "";
}
export const disconnect_google_account = async (refreshToken) => {
    let res;

    try {
        res = await axiosInstance.post(`/google-disconnect/`);
    } catch (error) {
        res = error
    }
    return res ? res : "";
}
export const delete_notification = async (eventId) => {
    let res;

    try {
        res = await axiosInstance.delete(`/event/${eventId}`);
    } catch (error) {
        res = error
    }
    return res ? res : "";
}
export const get_home_essential_types = async (eventId) => {
    let res;

    try {
        res = await axiosInstance.get(`/home-essential-types/`);
    } catch (error) {
        res = error
    }
    return res ? res : "";
}
export const getHomeEsstentialReminders = async (type) => {
    let res;
    try {
        if (type == 'homeEssential') {

            res = await axiosInstance.get(`/get-reminders/`);
        } else if (type == 'custom') {
            const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

            res = await axiosInstance.get(`/get-custom-reminders/?property_id=${propertyData.id}`);
        }

    } catch (error) { }
    return res ? res : "";
}

export const addHomeEssential = async (data) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        data.append('property_id', propertyData.id);

        res = await axiosInstance.post(`/add-home-essential/`, data);
    } catch (error) {
        res = error
    }
    return res ? res : "";

}
export const updateHomeEssential = async (data, id) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        data.append('property_id', propertyData.id);

        res = await axiosInstance.post(`/update-home-essential/${id}`, data);
    } catch (error) {
        res = error
    }
    return res ? res : "";

}
export const getReminderById = async (id) => {
    let res;
    try {

        res = await axiosInstance.get(`/get-reminder/${id}`);
    } catch (error) {
        res = error
    }
    return res ? res : "";

}
export const getHomeEsstentialList = async (id) => {
    let res;
    try {
        if (id) {

            const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
            res = await axiosInstance.get(`/home-essentials/${id}/?property_id=${propertyData.id}`);
        } else {

            const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
            res = await axiosInstance.get(`/home-essentials/?property_id=${propertyData.id}`);
        }


    } catch (error) { }
    return res ? res : "";
}


export const getProjectsList = async () => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/get-projects/?property_id=${propertyData.id}`);

    } catch (error) { }
    return res ? res : "";
}

// purchase item
export const AddPurchaseItem = async (data) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        data.append('property_id', propertyData.id);
        res = await axiosInstance.post(`/add-purchase`, data);
    } catch (error) {
        res = error
    }
    return res ? res : "";

}
export const getPurchaseList = async () => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/get-purchases/?property_id=${propertyData.id}`);

    } catch (error) { }
    return res ? res : "";
}
export const getPurchaseItem = async (id) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/get-purchase/${id}`);

    } catch (error) { }
    return res ? res : "";
}
export const updatePurchaseItem = async (id, data) => {
    let res;
    try {
        res = await axiosInstance.post(`/update-purchase/${id}`, data);

    } catch (error) { }
    return res ? res : "";
}
export const updateServiceItem = async (id, data) => {
    let res;
    try {
        res = await axiosInstance.post(`/update-service/${id}`, data);

    } catch (error) { }
    return res ? res : "";
}



// services item

export const getServiceList = async () => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/get-services/?property_id=${propertyData.id}`);

    } catch (error) { }
    return res ? res : "";
}
export const getServiceItem = async (id) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/get-service/${id}`);

    } catch (error) { }
    return res ? res : "";
}




export const AddServiceItem = async (data) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        data.append('property_id', propertyData.id);
        res = await axiosInstance.post(`/add-service`, data);
    } catch (error) {
        res = error
    }
    return res ? res : "";

}

// project

export const addProjectApis = async (data) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        data.append('property_id', propertyData.id);
        res = await axiosInstance.post(`/add-project`, data);
    } catch (error) {
        res = error
    }
    return res ? res : "";

}

export const updateProjectApis = async (data, id) => {
    let res;
    try {
        res = await axiosInstance.post(`/update-project/${id}`, data);
    } catch (error) {
        res = error
    }
    return res ? res : "";

}


export const addFileApis = async (data) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        data.append('property_id', propertyData.id);
        res = await axiosInstance.post(`/add-file`, data);
    } catch (error) {
        res = error
    }
    return res ? res : "";

}
export const updateFileApis = async (data,id) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        data.append('property_id', propertyData.id);
        res = await axiosInstance.post(`/update-file/${id}`, data);
    } catch (error) {
        res = error
    }
    return res ? res : "";

}
export const getFilesList = async () => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/get-files?property_id=${propertyData.id}`);

    } catch (error) { }
    return res ? res : "";
}