import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { get_event_by_id } from "../../../axiosCalls/userdashboard";
import moment from "moment";

const ReminderSideNavView = ({ eventID }) => {
    const [selectedReminder, setSelectedReminder] = useState(null);
    const [selectedHomeEssentials, setSelectedHomeEssentials] = useState([]);

    // Fetch reminder data when eventID changes
    useEffect(() => {
        const fetchReminder = async () => {
            if (eventID) {
                try {
                    const res = await get_event_by_id(eventID); // API call to fetch the event details
                    const reminderData = res.data;
                    setSelectedReminder(reminderData); // Set fetched reminder data to state
                    console.log(reminderData)
                    if (reminderData.linked_home_essentials) {

                        setSelectedHomeEssentials((reminderData.linked_home_essentials)); // Parse and set linked home essentials
                    }
                } catch (error) {
                    console.error("Error fetching reminder by ID:", error);
                }
            }
        };

        fetchReminder();
    }, [eventID]); // Re-fetch when eventID changes

    if (!selectedReminder) {
        return <p>Loading...</p>; // Show a loading state while fetching
    }else{
        
    }

    const { title, description, frequency, time, date } = selectedReminder;

    return (
        <>
            <div className='side-nav'>
                <div className='side-header'>
                    <h3>{title}</h3> {/* Dynamically render title */}
                </div>
                <div className='side-body homeEssentialSideNav'>
                    <Form>
                        <Row>
                            <Form.Group md="12" className="mb-3">
                                <div className='link-table-main'>
                                    <Form.Label>Description</Form.Label>
                                    <div className='warranty-section'>
                                        <span><b>Description</b></span>
                                        <span><p>{description}</p></span> {/* Dynamically render description */}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="4" className="mb-3">
                                <div className='link-table-main'>
                                    <Form.Label>Repeats</Form.Label>
                                    <p className='text-paragraph'>{frequency}</p> {/* Render repeat frequency */}
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="4" className="mb-3">
                                <div className='link-table-main'>
                                    <Form.Label>Date</Form.Label>
                                    <p className='text-paragraph'>{date}</p> {/* Render reminder date */}
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="4" className="mb-3">
                                <div className='link-table-main'>
                                    <Form.Label>Time</Form.Label>
                                    <p className='text-paragraph'>{moment(time, 'HH:mm:ss').format('hh:mm A')}</p> {/* Render reminder time */}
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className='link-table-main'>
                                    <Form.Label>Linked Home Essentials</Form.Label>
                                    <div className='listOfImages'>
                                        {selectedHomeEssentials.map((essential, index) => (
                                            
                                            <span key={index} className="services-badge type-blue">
                                                {essential.label} {/* Render linked home essentials */}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default ReminderSideNavView;
