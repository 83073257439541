import React, { useEffect, useState } from "react";

import icon1 from "../asstes/dash-icons/sidebar/dashIcon1.svg";
import icon2 from "../asstes/dash-icons/sidebar/dashIcon2.svg";
import icon3 from "../asstes/dash-icons/sidebar/dashIcon3.svg";
import icon4 from "../asstes/dash-icons/sidebar/dashIcon4.svg";
import icon5 from "../asstes/dash-icons/sidebar/dashIcon5.svg";
import warningIcon from "../asstes/dash-icons/warningIcon.png";
import HomeEssentialsSideNav from "../components/inc/homeEssentialsSideNav";
import HomeEssentialsSideNavView from "../components/inc/homeEssentialsSideNavView";
import HomeEssentialsSideNavEdit from "../components/inc/homeEssentialsSideNavEdit";
import axios from "axios";
import { addHomeEssential, getHomeEsstentialList, updateHomeEssential } from "../../axiosCalls/userdashboard";
import { toast } from "react-toastify";

const HomeEssentials = () => {
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [continueChange, setContinueChange] = useState('createStep');
    const [valueForView, setValueForView] = useState([]);
    const [updatedData, setUpdatedData] = useState([]);

    const [formData, setFormData] = useState({
        homeEssentialName: '',
        make: '',
        modelNumber: '',
        purchaseDate: '',
        files: [],
        warrantyFiles: [],
        images: [],
        notes: '',
        warrantyData: {
            name: '',
            number: '',
            startDate: '',
            endDate: '',
            notes: '',
            files: [],
        },
        linkedReminders: [],
        selectedType: '',
        warrantyCheck: 'unchecked',
    });
    // Handler to update the form data from the child component
    const updateFormData = (updatedData) => {
        setFormData((prevData) => ({
            ...prevData,
            ...updatedData,
        }));
    };
    const updatedFormData = (data) => {
        setUpdatedData(data);
    };

    const handleUpdateHomeEssential = async () => {


        const { name, model_number, make, purchase_date, age_of_home_essential, linked_reminders, notes, type, type_id ,warranty, warranty_data, files, images, warrantyFiles } = updatedData;

         // Validate required fields
        if (!name) {
            toast.error("Home Essential Name is required");
            return;
        }
        if (!type_id) {
            toast.error("Please select a Home Essential Type");
            return;
        }
        if (!model_number) {
            toast.error("Model Number is required");
            return;
        }
        if (!age_of_home_essential) {
            toast.error("Age of Home Essential is required");
            return;
        }
        if (!purchase_date) {
            toast.error("Purchase Date is required");
            return;
        }
        if (!files.length) {
            toast.error("At least one file is required");
            return;
        }
        if (!images.length) {
            toast.error("At least one image is required");
            return;
        }
        if (!make) {
            toast.error("Make Home Essential is required");
            return;
        }

        // If warranty is checked, validate warranty fields
        if (warranty) {
            if (!warranty_data[0].name) {
                toast.error("Warranty Name is required");
                return;
            }
            if (!warranty_data[1].number) {
                toast.error("Warranty Number is required");
                return;
            }
            if (!warranty_data[2].start_date) {
                toast.error("Warranty Start Date is required");
                return;
            }
            if (!warranty_data[3].end_date) {
                toast.error("Warranty End Date is required");
                return;
            }
        }

        let data = new FormData();
        data.append('type', type_id);
        data.append('name', name);
        data.append('make', make);
        data.append('model_number', model_number);
        data.append('age_of_home_essential', age_of_home_essential);
        data.append('purchase_date', purchase_date);
        linked_reminders.forEach(remind => {
            const reminderData = JSON.stringify({ id: remind.id, date: remind.start_date });
            data.append('linked_reminders[]', reminderData);
        });
                data.append('notes', notes);
        data.append('warranty', warranty);

        if (warranty) {
            data.append('warranty_data[][name]', warranty_data[0].name);
            data.append('warranty_data[][number]', warranty_data[1].number);
            data.append('warranty_data[][start_date]', warranty_data[2].start_date);
            data.append('warranty_data[][end_date]', warranty_data[3].end_date);
            data.append('warranty_data[][notes]', warranty_data[4].notes);
            data.append('warranty_data[files]', warranty_data.files);
            if (warrantyFiles) {
                warrantyFiles.forEach(file => data.append('warranty_data_files[]', file));
            }
        }

         files.forEach(file => data.append('files[]', file));
        images.forEach(image => data.append('images[]', image));
        warranty_data.files.forEach(image => data.append('warranty_data[files][]', image));



        try {
            const response = await updateHomeEssential(data, updatedData.id);
            if(response.status == 200){
                toast.success(response.data.message);
                handlerCancelSideNav();
            }
             // Optionally, trigger an update to the parent component or redirect
        } catch (error) {
            console.error('Error updating home essential:', error);
        }
    }
    // Function to handle the submit from parent
    const handleSubmitHomeEssentials = async () => {
        const { homeEssentialName, make, modelNumber, purchaseDate, ageofEssential, linkedReminders, notes, selectedType, warrantyCheck, warrantyData, files, images, warrantyFiles } = formData;
        // Validate required fields
        if (!homeEssentialName) {
            toast.error("Home Essential Name is required");
            return;
        }
        if (!selectedType) {
            toast.error("Please select a Home Essential Type");
            return;
        }
        if (!modelNumber) {
            toast.error("Model Number is required");
            return;
        }
        if (!ageofEssential) {
            toast.error("Age of Home Essential is required");
            return;
        }
        if (!purchaseDate) {
            toast.error("Purchase Date is required");
            return;
        }
        if (!make) {
            toast.error("Make Home Essential is required");
            return;
        }
        if (!files.length) {
            toast.error("At least one file is required");
            return;
        }
        if (!images.length) {
            toast.error("At least one image is required");
            return;
        }


        // If warranty is checked, validate warranty fields
        if (warrantyCheck === 'checked') {
            if (!warrantyData.name) {
                toast.error("Warranty Name is required");
                return;
            }
            if (!warrantyData.number) {
                toast.error("Warranty Number is required");
                return;
            }
            if (!warrantyData.startDate) {
                toast.error("Warranty Start Date is required");
                return;
            }
            if (!warrantyData.endDate) {
                toast.error("Warranty End Date is required");
                return;
            }
        }
        let data = new FormData();
        data.append('type', selectedType);
        data.append('name', homeEssentialName);
        data.append('make', make);
        data.append('model_number', modelNumber);
        data.append('age_of_home_essential', ageofEssential);
        data.append('purchase_date', purchaseDate);
        linkedReminders.forEach(remind => {
            const reminderData = JSON.stringify({ id: remind.id, date: remind.start_date });
            data.append('linked_reminders[]', reminderData);
        });        data.append('notes', notes);
        data.append('warranty', warrantyCheck === 'checked' ? 'true' : 'false');

        if (warrantyCheck === 'checked') {
            data.append('warranty_data[][name]', warrantyData.name);
            data.append('warranty_data[][number]', warrantyData.number);
            data.append('warranty_data[][start_date]', warrantyData.startDate);
            data.append('warranty_data[][end_date]', warrantyData.endDate);
            data.append('warranty_data[][notes]', warrantyData.notes);
            warrantyFiles.forEach(file => data.append('warranty_data_files[]', file));
        }

        files.forEach(file => data.append('files[]', file));
        images.forEach(image => data.append('images[]', image));



        try {
            const response = await addHomeEssential(data);
            if (response.data.post_id) {
                toast.success(response.data.message);
                handlerCancelSideNav();
            }
        } catch (error) {
            console.error('Error submitting home essential:', error);
        }
    };
    const handlerReminderSideNave = () => {

        setContinueChange('createStep');
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');


    }
    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        document.body.classList.remove('no-scroll');


    }
    const handlerChangeStep = (e) => {
        if (continueChange === 'suggets') {
            setContinueChange('EditStep');
        } else if (continueChange === 'createStep') {
            handleSubmitHomeEssentials();
        } else {
            handleUpdateHomeEssential();
        }

    }
    const handlerBackSideNav = () => {

        if (continueChange === 'createStep') {
            setContinueChange('suggets')
        } else {
            setContinueChange('suggets')

        }
    }

    const [homeEssentials, setHomeEssentials] = useState([]);

    // Fetch home essentials when the component is mounted
    useEffect(() => {
        const fetchHomeEssentials = async () => {
            try {
                const response = await getHomeEsstentialList();
                if (response && response.data && response.data.items) {
                    setHomeEssentials(response.data.items);
                } else {
                    console.error("No home essential data found");
                }
            } catch (error) {
                console.error("Error fetching home essentials:", error);
            }
        };

        fetchHomeEssentials();
    }, []);

    function HandlerViewHomeEsstional(id, postID) {

        setValueForView(postID);
        setContinueChange('suggets');

        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');


    }
    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <h5 className="mb-2 text-titlecase mb-4">Home Essentials</h5>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext">

                            <div className=" top-text-head">

                                <div className="reminder-top-button">


                                    <button className="filled-btn" onClick={handlerReminderSideNave}>

                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00065 3.33594V12.6693M3.33398 8.0026H12.6673" stroke="white" strokeLinecap="round" />
                                        </svg>

                                        Add home essential</button>

                                </div>


                            </div>
                        </div>
                        <div className="row paddingRight0" >
                            {homeEssentials.map((item, index) => (
                                <div
                                    className="col-lg-3 col-md-6 col-sm-6 item_each_home_cards"
                                    id={index}
                                    key={item.id} // Use item.id as the key for unique identification
                                    onClick={() => HandlerViewHomeEsstional(index, item.id)}
                                >
                                    <div className="dashboard-card">
                                        <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                            <div className="justify-content-between align-items-center">
                                                <div className="icon-box-card">
                                                    {/* Display the first image from the images array */}

                                                    <img src={`${icon1}`} alt={item.model_number} />


                                                    {item.warranty && (
                                                        <div className="card-warining">
                                                            <img alt="Warning" src={warningIcon} />
                                                            <span>Warning</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <p className="dashboard-heading">{item.name}</p>
                                                <p className="dashboard-subheading">{item.type}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>

            <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

            <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

                {continueChange === 'suggets' ? <HomeEssentialsSideNavView propsValue={valueForView} /> : continueChange === 'createStep' ? <HomeEssentialsSideNav formData={formData} updateFormData={updateFormData} /> : <HomeEssentialsSideNavEdit homeEssentialId={valueForView} updatedFormData={updatedFormData} />}


                <div className='side-footer'>

                    <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>

                    <button className='footer-button-primary ' onClick={handlerChangeStep}>{continueChange !== 'suggets' ? 'Save' : 'Edit'}</button>
                    {continueChange !== 'createStep' && continueChange !== 'suggets' ? <button className='footer-button-delete footer-button-back' >Delete</button> : ''}


                </div>

            </div>
        </div>
    );
}

export default HomeEssentials;
