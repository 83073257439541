import React, { useEffect, useState } from 'react';
import warningIcon from "../asstes/dash-icons/warningIcon.png";
import ProjectsSideNav from '../components/inc/projectsSideNav';
import ProjectsSideNavNew from '../components/inc/projectsSideNavNew';
import { Form } from 'react-bootstrap';
import ProjectSideNavEdit from '../components/inc/projectSideNavEdit';
import { addProjectApis, getProjectsList, updateProjectApis } from '../../axiosCalls/userdashboard';
import { toast } from 'react-toastify';
import { json } from 'react-router-dom';

const Projects = () => {
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [continueChange, setContinueChange] = useState('createStep');
    const [valueForView, setValueForView] = useState([]);
    const [formData, setFormData] = useState([]);
    const [updatedFormData, setUpdatedFormData] = useState([]);
    const [listProject, setListProjects] = useState([]); // Initialize an empty array to store the projects list
    const [sortOrder, setSortOrder] = useState('Latest'); // New state for sorting

    useEffect(() => {
        const handleGetProjectList = async () => {
            const res = await getProjectsList();
            if (res && res.data) {
                setListProjects(res.data); // Set the projects list to the state
            }
        }
        handleGetProjectList();
    }, [])

    // Function to sort projects based on the publishing_date
    const sortProjects = (order) => {
        const sortedProjects = [...listProject].sort((a, b) => {
            const dateA = new Date(
                a.publishing_date.substring(0, 4), // year
                a.publishing_date.substring(4, 6) - 1, // month (zero-based index)
                a.publishing_date.substring(6, 8), // day
                a.publishing_date.substring(8, 10), // hours
                a.publishing_date.substring(10, 12), // minutes
                a.publishing_date.substring(12, 14) // seconds
            );
            const dateB = new Date(
                b.publishing_date.substring(0, 4),
                b.publishing_date.substring(4, 6) - 1,
                b.publishing_date.substring(6, 8),
                b.publishing_date.substring(8, 10),
                b.publishing_date.substring(10, 12),
                b.publishing_date.substring(12, 14)
            );

            return order === 'Latest' ? dateB - dateA : dateA - dateB;
        });
        setListProjects(sortedProjects);
    };


    // Handle change of sorting option
    const handleSortChange = (e) => {
        const value = e.target.value;
        setSortOrder(value);
        sortProjects(value);
    };

    const handlerReminderSideNave = () => {
        setContinueChange('createStep');
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');
    }
    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        document.body.classList.remove('no-scroll');
    }
    const handlerChangeStep = () => {
        if (continueChange === 'createStep') {
            handleSubmitProject();
        } else if(continueChange === 'EditStep'){

            handleUpdateProject();

        }else{
            setContinueChange('EditStep');
        }
    }
    const handleSubmitProject = async () => {

        // Validation
        const { project_name, start_date, end_date, notes, linked_home_essentials, linked_services, linked_purchases, files, images } = formData;

        // Check for required fields
        if (!project_name) {
          toast.error("Project Name is required");
          return;
        }
        if (!start_date) {
          toast.error("Start Date is required");
          return;
        }
        if (!end_date) {
          toast.error("End Date is required");
          return;
        }
        if (!notes) {
          toast.error("Notes are required");
          return;
        }
        if (!linked_home_essentials.length) {
          toast.error("At least one Home Essential must be linked");
          return;
        }
        if (!linked_services.length) {
          toast.error("At least one Service must be linked");
          return;
        }
        if (!linked_purchases.length) {
          toast.error("At least one Purchase must be linked");
          return;
        }
        if (!files || files.length === 0) {
          toast.error("At least one file is required");
          return;
        }
        if (!images || images.length === 0) {
          toast.error("At least one image is required");
          return;
        }

        try {
          let data = new FormData();
          data.append('project_name', project_name);
          data.append('start_date', start_date);
          data.append('end_date', end_date);
          data.append('notes', notes);
          data.append('linked_home_essentials', JSON.stringify(linked_home_essentials));
          data.append('linked_services', JSON.stringify(linked_services));
          data.append('linked_purchases', JSON.stringify(linked_purchases));

          files.forEach(file => data.append('files[]', file));
          images.forEach(image => data.append('images[]', image));

          const res = await addProjectApis(data);
          if (res && res.status === 200) {
            toast.success(res.data.message);
            handlerCancelSideNav();
          }
        } catch (error) {
          console.error('Error submitting project:', error);
          toast.error("There was an error submitting the project. Please try again.");
        }
      };

      // Function to handle updating a project
      const handleUpdateProject = async () => {
        // Validation
        const { project_name, start_date, end_date, notes, linked_home_essentials, linked_services, linked_purchases, files, images } = updatedFormData;

        // Check for required fields
        if (!project_name) {
          toast.error("Project Name is required");
          return;
        }
        if (!start_date) {
          toast.error("Start Date is required");
          return;
        }
        if (!end_date) {
          toast.error("End Date is required");
          return;
        }
        if (!notes) {
          toast.error("Notes are required");
          return;
        }
        if (!linked_home_essentials.length) {
          toast.error("At least one Home Essential must be linked");
          return;
        }
        if (!linked_services.length) {
          toast.error("At least one Service must be linked");
          return;
        }
        if (!linked_purchases.length) {
          toast.error("At least one Purchase must be linked");
          return;
        }
        if (!files || files.length === 0) {
          toast.error("At least one file is required");
          return;
        }
        if (!images || images.length === 0) {
          toast.error("At least one image is required");
          return;
        }

        try {
          let data = new FormData();
          data.append('project_name', project_name);
          data.append('start_date', start_date);
          data.append('end_date', end_date);
          data.append('notes', notes);
          data.append('linked_home_essentials', JSON.stringify(linked_home_essentials));
          data.append('linked_services', JSON.stringify(linked_services));
          data.append('linked_purchases', JSON.stringify(linked_purchases));

          files.forEach(file => data.append('files[]', file));
          images.forEach(image => data.append('images[]', image));

          const res = await updateProjectApis(data, valueForView.ID);
          if (res && res.status === 200) {
            toast.success(res.data.message);
            handlerCancelSideNav();
          }
        } catch (error) {
          console.error('Error updating project:', error);
          toast.error("There was an error updating the project. Please try again.");
        }
      };

    const HandlerViewProject = (pro) => {

        setValueForView(pro);
        setContinueChange('suggets');
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');
    }

    const handleFormData = (data) => {
        setFormData(data);
    }
    const updatedData = (data)=>{
        setUpdatedFormData(data)
    }

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <h5 className="mb-2 text-titlecase mb-4">Projects</h5>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext">
                            <div className="top-text-head">
                                <div className="reminder-top-button">
                                    <div className='sortble-sec-projects'>
                                        <div>Sort by</div>
                                        <Form.Select value={sortOrder} onChange={handleSortChange}>
                                            <option value='Latest'>Latest</option>
                                            <option value='Older'>Older</option>
                                        </Form.Select>
                                    </div>
                                    <button className="filled-btn" onClick={handlerReminderSideNave}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00065 3.33594V12.6693M3.33398 8.0026H12.6673" stroke="white" strokeLinecap="round" />
                                        </svg>
                                        Add Project
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row paddingRight0">
                            {listProject.map((pro, index) => (
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-3 item_each_home_cards" id={index} key={index} onClick={() => HandlerViewProject(pro)}>
                                    <div className="dashboard-card">
                                        <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                            <div className="justify-content-between align-items-center">
                                                <div className="icon-box-card"></div>
                                                <p className="projects dashboard-heading">{pro.title}</p>
                                                <p className="dashboard-subheading row ProjectService">
                                                    {JSON.parse(pro.linked_home_essentials).map((ser, Childindex) => (
                                                        <span className={"childProjectService services-badge type-red"} key={Childindex}>{ser.label}</span>
                                                    ))}
                                                    {JSON.parse(pro.linked_services).map((ser, Childindex) => (
                                                        <span className={"childProjectService services-badge type-camel"} key={Childindex}>{ser.label}</span>
                                                    ))}
                                                    {JSON.parse(pro.linked_purchases).map((ser, Childindex) => (
                                                        <span className={"childProjectService services-badge type-green"} key={Childindex}>{ser.label}</span>
                                                    ))}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-footer'>
                                        Project Cost: ${pro.cost}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>
            <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
                {continueChange === 'suggets' ? <ProjectsSideNav propsValue={valueForView} /> : continueChange === 'createStep' ? <ProjectsSideNavNew handleFormData={handleFormData} /> : <ProjectSideNavEdit updatedData={updatedData} propsValue={valueForView}/>}
                <div className='side-footer'>
                    <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>
                    <button className='footer-button-primary' onClick={handlerChangeStep}>{continueChange !== 'suggets' ? 'Save' : 'Edit'}</button>
                </div>
            </div>
        </div>
    );
}

export default Projects;
